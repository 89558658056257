import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Link } from 'gatsby'
import { darken, lighten } from 'polished'

export const Container = styled.div`
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1400px;
  margin: 80px auto 40px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 40px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 20px auto 0px;
    grid-template-columns: 1fr;
    gap: 20px;
  }
`
export const Frame2Container = styled(Container)`
  grid-template-columns: 2fr 1fr;
  gap: 60px;
  justify-content: flex-start;
  @media (max-width: 1450px) {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr;
    gap: 0px;
    margin-top: 32px;
  }
`

export const CalMaxContainer = styled(Container)`
  grid-template-columns: 1fr 2fr;
  gap: 60px;
  justify-content: center;
  img {
    max-width: 100%;
    border-radius: 16px;
  }
  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 16px;
    div {
      order: 2;
    }
    > div:first-child {
      order: 2;
    }
    > div:last-child {
      order: 1;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    gap: 0px;
  }
`
export const MaxContainer = styled(CalMaxContainer)`
  margin-top: 56px;
  margin-bottom: 32px;
  @media (max-width: ${breakpoints.m}px) {
    margin-top: 32px;
  }
`

export const Heading = styled.h2`
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 0.15px;
  margin: 16px 0px 8px;
  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
    line-height: 32px;
    font-size: 26px;
  }
  @media (max-width: 400px) {
    font-size: 25px;
  }
  @media (max-width: 375px) {
    font-size: 23px;
  }
  @media (max-width: 350px) {
    font-size: 21px;
  }
`
export const Description = styled.p`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.09px;
  margin: 0px auto;
  color: ${(props) => props.theme.grayDark};
  text-align: center;
  @media (max-width: ${breakpoints.l}px) {
    margin: 0px auto 8px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 24px;
    margin: 0px auto;
  }
`

export const Card = styled.div`
  background-color: ${(props) => props.theme.blueLight};
  border-radius: 56px;
  padding: 24px;
  height: 100%;
`

export const CtaContainer = styled.div`
  padding: 30px 0px 20px;
  text-align: center;
  a {
    text-decoration: none;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding: 16px 0px 20px;
  }

  @media (max-width: ${breakpoints.s}px) {
    a {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
`
export const LogoContainer = styled.div`
  img {
    border-radius: 25px;
  }
`
export const TextContainer = styled.div`
  text-align: center;
`
export const CardLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 0px;
  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 40px;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`
export const BuyNowLinkCTA = styled.div`
  font-family: 'FilsonPro';
  background-color: ${(props) => props.theme.coral};
  color: white;
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  padding: 15px 30px;
  line-height: 1.28;
  letter-spacing: 0.09px;
  display: inline;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.08px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    display: block;
    text-align: center;
  }
`
export const CalMaxHeroVideo = styled.video`
  width: 100%;
`

export const PressContainer = styled.div`
  padding: 0px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  #time {
    width: 100px;
  }
  #techradar {
    width: 200px;
  }
  #verge {
    width: 180px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: -20px 0px 0px;
    #time {
      width: 70px;
    }
    #techradar {
      width: 150px;
    }
    #verge {
      width: 120px;
    }
    #zd {
      width: 60px;
    }
    flex-wrap: wrap;
  }
  @media (max-width: 540px) {
    margin: 0px 0px 30px;
    #time {
      width: 80px;
    }
    #techradar {
      width: 160px;
    }
    #verge {
      width: 180px;
    }
    #zd {
      width: 80px;
    }
  }
  @media (max-width: 540px) {
    max-width: 300px;
    margin: 0px auto 30px;
  }
`

export const PressIcon = styled.div`
  width: 80px;
  margin: 10px 20px;
  cursor: pointer;
  img {
    border-radius: 16px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 10px 10px 0px;
    width: 60px;
  }
  @media (max-width: 375px) {
    width: 50px;
  }
`

export const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const HideOnDesktop = styled.div`
  display: none;
  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`
export const HideOnMobile = styled.div`
  display: block;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const ImageContainer = styled.div`
  position: relative;
`
export const TimeIcon = styled.div`
  position: absolute;
  top: -6px;
  right: 60px;
  width: 120px;
  @media (max-width: 1450px) {
    right: 40px;
    width: 100px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    right: 40px;
    width: 80px;
  }
  @media (max-width: ${breakpoints.l}px) {
    right: 50px;
    width: 120px;
  }
  @media (max-width: ${breakpoints.m}px) {
    right: 20px;
    width: 90px;
  }
  @media (max-width: ${breakpoints.s}px) {
    right: 15px;
    width: 60px;
  }
`
export const TimeDisclosure = styled.div`
  position: absolute;
  right: 80px;
  bottom: 8px;
  text-align: right;
  @media (max-width: 1450px) {
    right: 50px;
    bottom: 2px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    right: 50px;
    bottom: 28px;
  }
  @media (max-width: ${breakpoints.l}px) {
    right: 80px;
    bottom: 2px;
  }
  @media (max-width: ${breakpoints.m}px) {
    right: 40px;
  }
  @media (max-width: ${breakpoints.s}px) {
    bottom: 4px;
  }
  @media (max-width: 450px) {
    right: 30px;
  }
`
export const TimeLegal = styled.p`
  font-family: 'FilsonPro';
  line-height: 1.2;
  font-size: 11px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 10px;
  }
  @media (max-width: 450px) {
    font-size: 8px;
    line-height: 1;
  }
`
