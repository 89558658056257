import React, { useState, useEffect } from 'react'

import all from 'src/images/calendar/all-in-one.svg'
import unite from 'src/images/calendar/calendars-unite.svg'
import plan from 'src/images/calendar/plan-anytime.svg'
import habit from 'src/images/calmax/habit-haven.svg'
import dinner from 'src/images/calendar/dinner-decided.svg'
import track from 'src/images/calendar/on-track.svg'
import allMobile from 'src/images/calendar/all-in-one-mobile.svg'
import uniteMobile from 'src/images/calendar/calendars-unite-mobile.svg'
import planMobile from 'src/images/calendar/plan-anytime-mobile.svg'
import dinnerMobile from 'src/images/calendar/dinner-decided-mobile.svg'
import trackMobile from 'src/images/calendar/on-track-mobile.svg'

import {
  ContentContainer,
  Header,
  ImageContainer,
  Title,
  Text,
  MobileImageContainer,
} from './styles'

import { CAL_MAX_FEATURES } from '../CalMaxFeatures/constants'

const CalendarNewFeatures = () => {
  const [activeImageIndex, setActiveImageIndex] = useState(-1)

  const handleScroll = () => {
    const sections = document.querySelectorAll('.left-column section')
    let activeIndex = -1

    sections.forEach((section, index) => {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY
      const sectionHeight = section.offsetHeight

      if (
        window.scrollY + window.innerHeight / 2.3 >= sectionTop &&
        window.scrollY + window.innerHeight / 2.3 < sectionTop + sectionHeight
      ) {
        activeIndex = index
      }
    })

    setActiveImageIndex(activeIndex)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const featureImages = [all, unite, plan, habit, dinner, track]
  const featureMobileImages = [allMobile, uniteMobile, planMobile, habit, dinnerMobile, trackMobile]

  return (
    <ContentContainer>
      <div className="container">
        <div className="left-column">
          <Header>Designed to Delight</Header>
          {CAL_MAX_FEATURES.map((feature, i) => (
            <section
              key={i}
              id={`section${i}`}
              className={activeImageIndex === i ? 'active-section' : 'inactive-section'}
            >
              <MobileImageContainer
                aria-label={feature.alt}
                key={feature.alt}
                type="image/svg+xml"
                data={featureMobileImages[i]}
              >
                svg-animation
              </MobileImageContainer>
              <Title>{feature.title}</Title>
              <Text>{feature.text}</Text>
            </section>
          ))}
        </div>
        <div className="right-column">
          {featureImages.map((image, index) => (
            <ImageContainer
              aria-label={CAL_MAX_FEATURES[index].alt}
              key={`${image.alt}-mobile`}
              type="image/svg+xml"
              data={image}
              className={activeImageIndex === index ? 'active-image' : ''}
              higher={index === 2 || index === 3 || index === 4}
              highest={index === 5}
            >
              svg-animation
            </ImageContainer>
          ))}
        </div>
      </div>
    </ContentContainer>
  )
}

export default CalendarNewFeatures
